
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import core from "@/core";
import EstimateService from "@/services/estimate/estimate.service";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import SpecialPriceService from "@/services/estimate/special-price.service";

export default mixins(MixinsComponent).extend({
  name: "SpecialPriceComponent",

  components: {},

  data: () => ({
    $viewer: null as any,
    address: "",
    type: "",
    estimate: null as any,
    specialPriceList: [] as any,
    viewerOptions: {
      inline: false,
      button: true,
      navbar: true,
      title: false,
      toolbar: true,
      tooltip: true,
      movable: true,
      zoomable: true,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: true,
      keyboard: false,
    },
  }),
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    core.loader.show();
    this.$nextTick(async () => {
      try {
        const query = this.$route.query;
        const placeId = query.placeId;
        const type = query.type;
        if (this.isNotBlank(type)) {
          //console.log("placeId : ", placeId, ", type : ", type);
          const specialPrice = (await SpecialPriceService.getByPlaceId(Number(placeId))) as any;
          //console.log("specialPrice : ", specialPrice);
          const detailList = [] as any;
          specialPrice.detailList.forEach((detail) => {
            if (detail.type === type) {
              detailList.push(detail);
            }
          });
          this.specialPriceList = detailList;
          //console.log("detailList: ", detailList);
          if (detailList.length === 0) {
            await core.alert.show({
              title: "알림",
              body: "잘못된 요청입니다.",
              allowBackCloseEvent: false,
            });
            this.$router.back();
            return;
          }

          const place = specialPrice.place;
          let address = "";
          if (place.address1 != null) {
            if (place.address2 != null) {
              address = `${place.address1} ${place.address2}`;
            } else {
              address = `${place.address1}`;
            }
          } else {
            address = `${place.name}`;
          }
          this.address = address;
          this.type = String(type);
        } else {
          await core.alert.show({
            title: "알림",
            body: "잘못된 요청입니다.",
            allowBackCloseEvent: false,
          });
          this.$router.back();
          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }

      //this.getEstimate();
    });
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    async getEstimate() {
      core.loader.show();
      try {
        const estimate = (this.estimate = await EstimateService.get()) as any;
        //console.log("estimate : ", this.estimate);
        const specialPrice = this.estimate.specialPrice;
        if (specialPrice != null) {
          specialPrice.detailList.forEach((detail) => {
            if (detail.type === this.estimate.type) {
              this.specialPriceList.push(detail);
            }
          });
          //console.log("specialPriceList : ", this.specialPriceList);
        }
        const place = estimate.place;
        let address = "";
        if (place.address1 != null) {
          if (place.address2 != null) {
            address = `${place.address1} ${place.address2}`;
          } else {
            address = `${place.address1}`;
          }
        } else {
          address = `${place.name}`;
        }
        this.address = address;
        this.type = estimate.type;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    onView(detail) {
      const imageUri = detail.imageUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
      // window.open(detail.imageUri, "_blank");
    },
  },
});
