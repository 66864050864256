
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "EventAlertModal",
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {
    call(phone) {
      document.location.href = "tel:" + phone;
    },
    confirm() {
      //console.log('confirm');
      this.close(UpdateEventType.CONFIRM);
    },

    cancel() {
      //console.log("cancel");
      this.close(UpdateEventType.CANCEL);
    },
  },
});
