import core from "@/core";
import Vue from "vue";
import UpdateEvent from "@/models";
import { RouteMeta } from "@/router";
import store from "@/store";

/**
 * 공통 처리
 */
export default Vue.extend({
  data: () => ({
    // 업데이트 이벤트 처리용 변수
    updateEvent: null as UpdateEvent | null,
    // dateFormatPattern: "YYYY-MM-DD",
    // dateTimeFormatPattern: "YYYY-MM-DD HH:mm",
    dateFormatPattern: "YYYY.MM.DD(ddd)",
    dateTimeFormatPattern: "YYYY.MM.DD(ddd) a hh:mm",
    iconSubMenu: {} as any,
    textSubMenu: {} as any,
    subMenu: {} as any,
    app: store.state.app,
  }),
  async created() {
    const subMenu = (this.$route.meta as RouteMeta).subMenu;
    if (subMenu != null) {
      this.subMenu = subMenu;
      ["icon", "text"].forEach((key) => {
        if (subMenu[key] != null) {
          const menu = subMenu[key];
          // console.log("menu : ", menu);
          if (subMenu[key].list != null) {
            subMenu[key].list.forEach((menu) => {
              if (key === "icon") {
                this.iconSubMenu[menu.id] = menu;
              } else if (key === "text") {
                this.textSubMenu[menu.id] = menu;
              }
            });
          }
        }
      });
      // console.log(this.iconSubMenu);
      // console.log(this.textSubMenu);
    }
  },
  methods: {
    isBlank(str) {
      return core.utils.validate.isBlank(str);
    },
    isNotBlank(str) {
      return core.utils.validate.isNotBlank(str);
    },
    moneyFormat(str: string) {
      return core.utils.format.moneyKor(str);
    },
    moneyCommaFormat(str: string) {
      return core.utils.format.money(str);
    },
    dateElapsedTimeFormat(date: string) {
      return core.date.format.elapsedTime(date);
    },
    dateFormat(date: string) {
      return core.date.instance(date).format(this.dateFormatPattern);
    },
    dateTimeFormat(date: string) {
      return core.date.instance(date).format(this.dateTimeFormatPattern);
    },
    phoneFormat(phone: string) {
      return core.utils.format.hyphenPhone(phone);
    },
    async findElement(refName): Promise<any> {
      return new Promise((resolve, reject) => {
        // html element focus 이동
        if (this.$refs[refName] != null) {
          // console.log(`ref '${refName}', focus!`);
          resolve(this.$refs[refName]);
          return;
        }
        const startFocusTimeMillis = new Date().getTime();
        const focusInterval = setInterval(() => {
          if (this.$refs[refName] != null) {
            clearInterval(focusInterval);
            // console.log(`ref '${refName}', focus!`);
            resolve(this.$refs[refName]);
          } else {
            const diffTimeMillis = new Date().getTime() - startFocusTimeMillis;
            if (diffTimeMillis > 2000) {
              clearInterval(focusInterval);
              console.log(`ref '${refName}', focus timeout(2000)`);
              // reject(`ref '${refName}', focus timeout(2000)`);
              resolve(null);
            }
          }
        }, 200);
      });
    },
    moveFocus(refName) {
      // html element focus 이동
      const startFocusTimeMillis = new Date().getTime();
      const interval = setInterval(() => {
        if (this.$refs[refName] != null) {
          (this.$refs[refName] as any).focus();
          clearInterval(interval);
          console.log(`ref '${refName}', focus!`);
        } else {
          const diffTimeMillis = new Date().getTime() - startFocusTimeMillis;
          if (diffTimeMillis > 2000) {
            clearInterval(interval);
            console.log(`ref '${refName}', focus timeout(2000)`);
          }
        }
      }, 200);
    },
    moveScroll(refName) {
      // html element scroll 이동
      const startFocusTimeMillis = new Date().getTime();
      const interval = setInterval(() => {
        if (this.$refs[refName] != null) {
          (this.$refs[refName] as any).scrollIntoView(true);
          clearInterval(interval);
          console.log(`ref '${refName}', scroll!`);
        } else {
          const diffTimeMillis = new Date().getTime() - startFocusTimeMillis;
          if (diffTimeMillis > 2000) {
            clearInterval(interval);
            console.log(`ref '${refName}', scroll timeout(2000)`);
          }
        }
      }, 200);
    },
  },
});
