import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";

const state: AppState = {
  version: process.env.VUE_APP_VERSION || "0",
  name: Constant.appName,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  path: "",
  showNav: true,
  bottomNav: "",
  isApp: false,
  isMobile: false,
  isMobileSize: false,
  data: {
    ready: false,
  },
};

export const getters: GetterTree<AppState, RootState> = {};

export const actions: ActionTree<AppState, RootState> = {};

const mutations: MutationTree<AppState> = {};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
