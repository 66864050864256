
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import SpecialPriceService from "@/services/estimate/special-price.service";
import EventService from "@/services/event/event.service";
import { EventModel } from "@/models/event/event.model";
import HintView from "@/components/core/HintView.vue";
import { EventFormV1Model, StorageV1Model } from "@/views/Roulette.vue";
import PlaceService from "@/services/place/place.service";

export default mixins(MixinsPage).extend({
  name: "Home",
  components: { HintView },
  data: () => ({
    ready: false,
    visible: {
      specialPrice: false,
      phone: false,
      phoneList: false,
      event: false,
      eventAnimation: false,
    },
    phone: "",
    phoneList: [] as any,
    event: null as EventModel | null,
  }),
  mounted() {
    core.loader.show();
    this.$nextTick(async () => {
      try {
        const query = this.$route.query;
        const placeId = query.placeId;

        if (this.isNotBlank(placeId)) {
          try {
            const event = (await EventService.getByPlaceId(Number(placeId))) as EventModel;
            if (event.status === "ACTIVE") {
              this.visible.event = true;
              this.event = event;
              // 이벤트 처리 내역 없으면 애니메이션 활성화
              this.visible.eventAnimation = true;
              const storageItems = (await this.$store.dispatch(
                "storage/getStorageItem",
                "roulette-event"
              )) as StorageV1Model;
              if (storageItems.version === 1) {
                const items = storageItems.items;
                if (items[event.id] != null) {
                  const eventForm = items[event.id] as EventFormV1Model | null;
                  if (eventForm != null) {
                    this.visible.eventAnimation = false;
                  }
                }
              } else {
                console.log("unknown storage version : ", storageItems);
              }
            }
          } catch (e) {
            console.log(e);
          }

          try {
            const phoneList = (await PlaceService.getPhoneList(Number(placeId))) as any;
            this.phoneList = phoneList;
            this.visible.phoneList = phoneList.length > 0;
          } catch (e) {
            console.log(e);
          }
        }

        const type = query.type;
        if (this.isNotBlank(query.phone)) {
          this.visible.phone = true;
          this.phone = String(query.phone);
        }
        if (this.isNotBlank(query.phoneList)) {
          this.visible.phoneList = true;
          const phoneList = String(query.phoneList).split(",");
          phoneList.forEach((phone) => {
            if (this.isNotBlank(phone)) {
              this.phoneList.push(phone);
            }
          });
          //console.log("this.phoneList : ", this.phoneList);
        }
        if (this.isNotBlank(type)) {
          //console.log("placeId : ", placeId, ", type : ", type);
          const specialPrice = (await SpecialPriceService.getByPlaceId(Number(placeId))) as any;
          //console.log("specialPrice : ", specialPrice);
          const detailList = [] as any;
          specialPrice.detailList.forEach((detail) => {
            if (detail.type === type) {
              detailList.push(detail);
            }
          });
          //console.log("detailList : ", detailList);
          this.visible.specialPrice = true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.ready = true;
        core.loader.hide();
      }
      // this.visible.specialPrice = true;
      // this.visible.event = true;
      // this.visible.phone = true;
    });
  },
  methods: {
    showRoulettePage() {
      // console.log("roulettePage : ", eventCategory);
      // this.$router.push({
      //   path: "/roulette",
      //   query: {
      //     eventId: eventCategory.eventId,
      //     categoryId: eventCategory.category.id,
      //   },
      // });
      if (this.event != null) {
        this.$router.push({
          path: "/roulette",
          query: {
            eventId: String(this.event.id),
          },
        });
      }
    },
    showBlogPage() {
      window.open("https://blog.naver.com/sjsoohan", "_blank");
    },
    getPhoneHtml() {
      const phone = core.utils.format.hyphenPhone("1600-6275");
      return `<a href="tel:${phone}">${phone}</a>`;
    },
    moveSpecialPricePage() {
      const query = this.$route.query;
      const placeId = query.placeId;
      const type = query.type;
      this.$router.push({
        path: "/special-price",
        query: {
          placeId: placeId,
          type: type,
        },
      });
    },
    call(phone) {
      document.location.href = "tel:" + phone;
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        this.$router.push("/logout");
      }
    },
  },
});
