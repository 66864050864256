import core from "@/core";
import {
  EventCategoryModel,
  EventModel,
  EventRouletteWinnerModel,
} from "@/models/event/event.model";

class EventService {
  get(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/event/${id}`, null)
        .then((data) => {
          resolve(data.event);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getByPlaceId(placeId: number): Promise<EventModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/event/place/${placeId}`, { ignoreAlertModal: true })
        .then((data) => {
          resolve(data.event);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getCategoryRouletteList(id: number): Promise<EventCategoryModel[]> {
    // /category-roulette
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/event/${id}/category-roulette`, null)
        .then((data) => {
          resolve(data.eventCategoryList);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getCategoryRoulette(id: number, categoryId: number) {
    // /category-roulette
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/event/${id}/category-roulette/${categoryId}`, null)
        .then((data) => {
          resolve(data.eventCategory);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getRouletteWinnerList(params): Promise<EventRouletteWinnerModel[]> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/event/roulette/winner`, null, params)
        .then((data) => {
          resolve(data.rouletteWinnerList);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  rouletteCustomer(params): Promise<EventRouletteWinnerModel> {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/p/event/roulette/customer`, strParams)
        .then((data) => {
          if (data.winner != null) {
            resolve(data.winner);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new EventService();
