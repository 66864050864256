
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SpecialPriceAlertModal",
  data: () => ({}),
  watch: {},
  methods: {
    confirm() {
      //console.log('confirm');
      this.close(UpdateEventType.CONFIRM);
    },

    cancel() {
      //console.log("cancel");
      this.close(UpdateEventType.CANCEL);
    },
  },
});
