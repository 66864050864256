
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SelectPlaceTypeModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            typeList: [] as string[],
          },
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
