var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(
      _vm.prop.menuType === _vm.MenuType.HOME ||
      _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BOTTOMBAR ||
      _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BACK ||
      _vm.prop.menuType === _vm.MenuType.NAVIGATION ||
      _vm.prop.menuType === _vm.MenuType.BACK ||
      _vm.prop.menuType === _vm.MenuType.TITLE ||
      _vm.prop.menuType === _vm.MenuType.BOTTOMBAR
    )?_c('app-bar',{attrs:{"prop":_vm.prop}}):_vm._e(),_c('vue-page-stack',[_c('router-view',{key:_vm.$route.fullPath})],1),(_vm.prop.menuType === _vm.MenuType.BOTTOMBAR || _vm.prop.menuType === _vm.MenuType.AUTO_NAV_BOTTOMBAR)?_c('app-bottom-bar',{attrs:{"prop":_vm.prop}}):_vm._e(),(_vm.app.modal.alert.visible)?_c('alert-modal',{attrs:{"properties":_vm.app.modal.alert}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }