import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import core from "@/core";
import store from "@/store";
import Constant from "@/store/constant";
import SpecialPrice from "@/views/SpecialPrice.vue";
import Roulette from "@/views/Roulette.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  HOME = "home",
  AUTO_NAV_BOTTOMBAR = "auto_nav_battombar",
  AUTO_NAV_BACK = "auto_nav_back",
  NAVIGATION = "navigation",
  BACK = "back",
  TITLE = "title",
  NONE = "none",
  BOTTOMBAR = "bottombar",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
  badge: false;
  badgeCount: number;
}

export interface OgMeta {
  description: string;
  image: string;
}

export interface RouteMeta {
  title: string;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;
  scrollable: boolean;
  ogMeta: OgMeta | null;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "",
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/price",
    redirect: "/home",
    meta: {
      title: "",
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
      // ogMeta: {
      //   description: "행운의 룰렛 주인공이 되보세요",
      //   image: "/img/roulette/test.png",
      // },
    } as RouteMeta,
  },
  {
    path: "/special-price",
    name: "specialPrice",
    component: SpecialPrice,
    meta: {
      title: "이벤트 특가표",
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/roulette",
    name: "roulette",
    component: Roulette,
    meta: {
      title: "행운의 룰렛 이벤트",
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },

  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "지원하지 않는 브라우저 입니다",
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "페이지를 찾을 수 없습니다",
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

async function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return "";
}

async function beforeEach(to, from, next) {
  next();
  return to.meta;
}

let first = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  // console.log("from " + from.path + " -> to : " + to.path);
  if (first) {
    first = false;
    core.preloader.show();
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  // if (
  //   (from.path === "/home" && to.path === "/login") ||
  //   (from.path === "/login" && to.path === "/login")
  // ) {
  //   window.history.back();
  //   return;
  // }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    if (meta.menuType !== MenuType.NONE) {
      await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: true });
    }

    if (!meta.scrollable) {
      document.documentElement.classList.add("overflow-y-hidden");
    } else {
      document.documentElement.classList.remove("overflow-y-hidden");
    }

    if (meta.ogMeta) {
      // setMetaContent('meta[property="og:title"]', "제목");
      setMetaContent('meta[property="og:description"]', meta.ogMeta.description);
      setMetaContent('meta[property="og:image"]', meta.ogMeta.image);
    }
  }

  if (core.preloader.isShow()) {
    core.preloader.hide();
  }
});

function setMetaContent(key, value) {
  if (core.utils.validate.isNotBlank(value)) {
    const node = document.querySelector(key);
    if (node != null) {
      node.content = value;
    }
  }
}

export default router;
