import core from "@/core";

class SpecialPriceService {
  getByPlaceId(placeId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/public/special-price`, null, { placeId: placeId })
        .then((data) => {
          if (data.specialPrice != null) {
            resolve(data.specialPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new SpecialPriceService();
