import core from "@/core";

class PlaceService {
  getPhoneList(placeId: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/p/place/${placeId}/phone`, { ignoreAlertModal: true })
        .then((data: any) => {
          if (data.phoneList != null) {
            resolve(data.phoneList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new PlaceService();
