
import mixins from "vue-typed-mixins";

export default mixins().extend({
  name: "HintView",
  data: () => ({
    visible: true,
  }),
  mounted() {
    this.$nextTick(() => {});
  },
});
