
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import EventService from "@/services/event/event.service";
import core from "@/core";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import RouletteComponent from "@/components/roulette/RouletteComponent.vue";
import MixinsStorage from "@/mixins/single/storage";
import { UpdateEventType } from "@/types";
import { EventCategoryModel, EventRouletteWinnerModel } from "@/models/event/event.model";
import UpdateEvent from "@/models";
import RouletteItems from "@/data/roulette-items";
import { cloneDeep } from "lodash";
import PlaceService from "@/services/place/place.service";
import SpecialPriceAlertModal from "@/modals/SpecialPriceAlertModal.vue";
import SelectPlaceTypeModal from "@/modals/SelectPlaceTypeModal.vue";
import EstimateService from "@/services/estimate/estimate.service";
import EventAlertModal from "@/modals/EventAlertModal.vue";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    phone: "휴대전화 ",
    dong: "동 ",
    ho: "호 ",
  },
});

export interface EventFormV1Model {
  eventId: number;
  dong: string;
  ho: string;
  phone: string;
  timestamp: number;
}

export interface StorageV1Model {
  version: number;
  items: { number: EventFormV1Model };
}

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "Roulette",
  components: { EventAlertModal, SelectPlaceTypeModal, SpecialPriceAlertModal, RouletteComponent },
  data: () => ({
    storageItems: {
      type: "roulette-event",
    },
    form: {
      phone: "",
      dong: "",
      ho: "",
    },
    processing: false,
    eventId: 0,
    categoryId: 0,
    event: null as any,
    eventCategoryComponentList: [] as any,
    visible: {
      retryButton: false,
      roulette: false,
      eventAlert: false,
    },
    eventCategoryList: null as EventCategoryModel[] | null,
    winnerList: null as any,
    step: 1, // STEP 1, 휴대전화, 동, 호수 인증 전
    watchItem: null as any,
    phoneList: [] as string[],
    imageSrcUri: "",
    modal: {
      eventAlertModal: {
        visible: false,
        params: {
          phoneList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
      specialPriceAlert: {
        visible: false,
        params: {},
        updateEvent: null as UpdateEvent | null,
      },
      selectPlaceType: {
        visible: false,
        params: {
          typeList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      // eventId : 14, categoryId : 1
      try {
        const query = this.$route.query;
        if (this.isBlank(query.eventId)) {
          await this.notFound();
          return;
        }
        const eventId = Number(query.eventId);
        this.eventId = eventId;

        const event = (await EventService.get(eventId)) as any;
        this.event = event;
        this.imageSrcUri = "/img/etc/gifts.jpg";
        if (event.imageType === "B") {
          this.imageSrcUri = "/img/etc/gifts_2.jpg";
        }
        const placeId = event.place.id;
        try {
          if (event.place.typeList != null) {
            this.modal.selectPlaceType.params.typeList = event.place.typeList.split(",");
          }
        } catch (e) {
          console.log(e);
          this.modal.selectPlaceType.params.typeList = [];
        }

        console.log("event.place : ", event.place);

        PlaceService.getPhoneList(Number(event.place.id)).then((phoneList) => {
          this.phoneList = phoneList as string[];
          this.modal.eventAlertModal.params.phoneList = this.phoneList;
        });
        //console.log("event : ", event);

        this.eventCategoryList = await EventService.getCategoryRouletteList(event.id);

        await this.loadStorageItems();

        const storageItems = this.getStorageItems();
        const items = storageItems.items;
        let eventForm = items[eventId] as EventFormV1Model | null;
        this.watchItem = this.$watch(
          "eventCategoryComponentList",
          function (newValue, oldValue) {
            if (newValue != null) {
              newValue.forEach((properties) => {
                const event = this.getComponentUpdateEvent(properties);
                if (event != null) {
                  if (event.result === UpdateEventType.CONFIRM) {
                    let winnerCount = 0;
                    this.eventCategoryComponentList.forEach((_properties) => {
                      if (_properties.params.winner != null) {
                        winnerCount++;
                      }
                    });
                    this.visible.eventAlert =
                      winnerCount > 0 && winnerCount === this.eventCategoryComponentList.length;

                    if (this.visible.eventAlert) {
                      this.showEventAlertModal();
                    }
                    // console.log("winnerCount : ", winnerCount);
                    // console.log(
                    //   "eventCategoryComponentList : ",
                    //   this.eventCategoryComponentList.length
                    // );
                    // console.log("this.visible.eventAlert : ", this.visible.eventAlert);
                  } else {
                    console.log("unknown event, ", event);
                  }
                }
              });
            }
          },
          { deep: true }
        );

        // const startTest = (i) => {
        //   if (i === 99) return;
        //
        //   setTimeout(async () => {
        //     await this.resetForm();
        //     this.form.phone = "010995494" + String(i);
        //     this.form.dong = "102";
        //     this.form.ho = String(i);
        //     setTimeout(async () => {
        //       await this.submit();
        //
        //       const clickRoulette = () => {
        //         setTimeout(() => {
        //           const el = document.querySelector(".vue-winwheel button") as any;
        //           if (el != null) {
        //             el.click();
        //             console.log("done");
        //             setTimeout(() => {
        //               startTest(i + 1);
        //             }, 500);
        //           } else {
        //             clickRoulette();
        //           }
        //         }, 200);
        //       };
        //       clickRoulette();
        //     }, 200);
        //   }, 200);
        // };
        // startTest(10);
      } catch (e) {
        console.log(e);
        this.notFound();
      }
    });
  },
  watch: {
    "form.phone"(val) {
      this.form.phone = core.utils.format.hyphenPhone(val);
    },
    "modal.specialPriceAlert.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.specialPriceAlert);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          this.showSelectPlaceTypeModal();
        }
      }
    },
    "modal.selectPlaceType.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectPlaceType);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const type = event.item;
          this.submitSpecialPrice(type);
        }
      }
    },
  },
  destroyed() {
    if (this.watchItem) {
      this.watchItem();
    }
  },
  methods: {
    showEventAlertModal() {
      const modal = this.modal.eventAlertModal;
      modal.visible = true;
    },
    showSpecialPriceAlertModal() {
      const modal = this.modal.specialPriceAlert;
      modal.visible = true;
    },
    showSelectPlaceTypeModal() {
      const modal = this.modal.selectPlaceType;
      modal.visible = true;
    },
    call(phone) {
      document.location.href = "tel:" + phone;
    },
    resetForm() {
      this.processing = false;
      this.form.phone = "";
      this.form.dong = "";
      this.form.ho = "";

      this.visible.eventAlert = false;
      this.visible.roulette = false;
      this.eventCategoryComponentList = [];
      this.step = 1;
      this.resetValidate();
      this.moveFocus("phone");
    },
    async loadStorageItems() {
      await this.storageWait();
      const storageItems = this.getStorageItems();
      //console.log("storageItems : ", storageItems);
      if (storageItems != null) {
        if (storageItems.version == null || storageItems.version < 1) {
          this.storageItems.items = {
            version: 1,
            items: {},
          } as StorageV1Model;
          this.saveStorageItems();
        }
        const eventId = this.eventId;
        const items = storageItems.items;
        if (items[eventId] != null) {
          const eventForm = items[eventId] as EventFormV1Model;
          for (const key of Object.keys(this.form)) {
            if (eventForm[key]) {
              this.form[key] = eventForm[key];
            }
          }
          setTimeout(() => {
            this.submit();
          }, 0);
        }
      }
    },
    saveFormStorageItems() {
      const items = this.getStorageItems("items") as { number: EventFormV1Model };
      const eventId = this.eventId;
      const form = this.form;
      items[eventId] = {
        eventId: eventId,
        dong: form.dong,
        ho: form.ho,
        phone: form.phone,
        timestamp: new Date().getTime(),
      };
      this.saveStorageItems();
    },
    async submitSpecialPrice(type) {
      if (await this.validate()) {
        console.log("submit type : ", type);
        const event = this.event;
        const form = this.form;

        const params = {
          placeId: event.place.id,
          dong: form.dong,
          ho: form.ho,
          customerPhone: form.phone,
          customerName: "익명",
          type: type,
          memo: "",
          remark: "",
          detailList: [],
        };
        const response = (await EstimateService.createSpecialPrice(params)) as any;
        // https://customer.smart-line.co.kr/login?v=MDEwOTk1NDkzMDMsNzk4NQ==
        const confirmResult = await core.alert.show({
          title: "알림",
          body: "특가표를 확인하러 이동하시겠습니까?",
          confirmButtonText: "예",
          cancelButtonText: "아니오",
          showCancelButton: true,
        });
        if (confirmResult === "confirm") {
          let strParam = this.form.phone.replaceAll("-", "") + "," + response.authCode;
          // console.log("strParam : ", strParam);
          strParam = btoa(strParam);
          // console.log("strParam : ", strParam);
          const url = "https://customer.smart-line.co.kr/login?v=" + strParam;
          // console.log("url : ", url);
          location.href = url;
        }
      }
    },
    async submit() {
      if (await this.validate()) {
        //console.log("submit");
        this.processing = true;
        //
        const form = this.form;
        try {
          // 당첨 정보 조회
          const params = {
            phone: form.phone,
            placeId: this.event.place.id,
            dong: form.dong,
            ho: form.ho,
          };
          const winnerList = await EventService.getRouletteWinnerList(params);
          //console.log("winnerList : ", winnerList);
          this.winnerList = winnerList;
          if (winnerList.length > 0) {
            // 당첨된 내역이 존재할 경우
            //console.log("winnerList : ", winnerList);
            let winner = null as EventRouletteWinnerModel | null;
            winnerList.forEach((winnerResult) => {
              console.log("winner : ", winnerResult);
              if (winnerResult.dong != this.form.dong || winnerResult.ho != this.form.ho) {
                winner = winnerResult;
              }
            });
            if (winner != null) {
              const body = `입력하신 휴대전화 번호는 이미 ${winner.dong}동 ${winner.ho}호 에서 참여하셨습니다.`;
              await core.alert.show({ title: "알림", body: body });
              this.form.dong = winner.dong;
              this.form.ho = winner.ho;
            }
          }
          // console.log("winnerList : ", winnerList);
        } catch (e) {
          this.processing = false;
          return;
        }

        let winnerCount = 0;
        const eventCategoryList = this.eventCategoryList as EventCategoryModel[];
        let showCategory = eventCategoryList.length > 1;
        eventCategoryList.forEach((eventCategory) => {
          let winner = null as any;
          if (this.winnerList != null) {
            this.winnerList.some((_winner) => {
              if (_winner.categoryId === eventCategory.category.id) {
                winner = _winner;
                return true;
              }
            });
          }
          if (winner != null) {
            winnerCount++;
          }
          const properties = {
            params: {
              showCategory: showCategory,
              rouletteList: [] as any,
              eventCategory: eventCategory,
              eventId: eventCategory.eventId,
              categoryId: eventCategory.category.id,
              winner: winner,
              form: {
                phone: form.phone,
                dong: form.dong,
                ho: form.ho,
              },
            },
            updateEvent: null as UpdateEvent | null,
          };

          eventCategory.rouletteList.forEach((roulette, index) => {
            const item = RouletteItems[index % RouletteItems.length];
            const params = cloneDeep(item) as any;
            params.text = roulette.item;
            params.data = roulette;
            properties.params.rouletteList.push(params);
          });

          this.eventCategoryComponentList.push(properties);
        });

        this.visible.retryButton = true;
        this.visible.roulette = true;

        if (eventCategoryList.length > 0) {
          if (eventCategoryList.length === winnerCount) {
            // 이벤트 모두 참여했을 경우 최하단으로 스크롤 이동
            this.moveScroll("rouletteResultAlert");
          } else {
            this.moveScroll("rouletteScroll");
          }
        }
        this.processing = false;
        this.step = 2;

        this.saveFormStorageItems();
      }
    },
  },
});
