export default [
  {
    textFillStyle: "#000",
    fillStyle: "#F99F98",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F9D34B",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#9FC2E7",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#ABCE65",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F7F7F7",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#B4E0E8",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F9B55A",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#C6A3DF",
    text: "",
  },
];

// export default [
//   {
//     textFillStyle: "#000",
//     fillStyle: "#F4F6F8",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#FFD67B",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#9579DA",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#F5B945",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#5E9CEA",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#FA6C51",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#73B0F4",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#EB5463",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#66D4F1",
//     text: "",
//   },
//   {
//     textFillStyle: "#000",
//     fillStyle: "#EB86BE",
//     text: "",
//   },
// ];
